<template>
	<div class="input-wrapper" v-show="true">
		<label for="webinars-filter-input" class="label__input">{{ title || 'Фильтр'}}</label>

		<input
			ref="mainInput"
			type="text"
			name="webinars-filter"
			id="webinars-filter-input"
			list="webinars-filter"
			class="select input__slim"
			:placeholder="placeholder"
			v-model="search_req"
			@click="toggleList"
			@input="show_list = true"
		>
		<span class="expand-icon"></span>
		<fieldset
			id="webinars-filter"
			v-if="show_list"
			@keyup.esc="show_list = false"
		>
			<div
				class="datalist-checkbox-wrapper"
				v-for="item, key in filteredItems"
				:key="'business-type-'+key"
				ref="optionsList"
			>
				<input
					type="checkbox"
					:id="'item-n-'+key"
					:value="item.type || item.title || item.name"
					class="visually-hidden"
					v-model="selected_items"
				>
				<label
					:for="'item-n-'+key"
					@click="labelClicked"
				>
					{{ item.type || item.title || item.name }}
				</label>
			</div>
		</fieldset>

		<ul class="filters-wrapper" v-show="true">
			<li
				class="link__btn btn__filter btn__filter-name"
				v-for="s_item, key in selected_items"
				:key="'selected-item-'+key"
			>
				<p>{{ s_item }}</p>
				<button
					type="button"
					class="btn__close"
					@click="removeItemFromList(s_item)"
				></button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			search_req: null,
			selected_items: [],
			show_list: false,
		}
	},
	props: ['items', 'title', 'placeholder'],
	computed: {
		filteredItems() {
			const items = this.items
			return this.search_req != null ? items.filter(item => item.type.toLowerCase().includes(this.search_req.toLowerCase())) : items
		},
	},
	methods: {
		removeItemFromList(s_item) {
			this.selected_items = this.selected_items.filter(item => item != s_item)
		},
		toggleList() {
			this.show_list = this.show_list ? false : true
		},
		clickHandler(event) {
			if(!this.$refs.optionsList.includes(event.target.parentNode) && event.target != this.$refs.mainInput) this.toggleList()
		},
		labelClicked() {
			this.search_req = null
		}
	},
	mounted() {
		document.addEventListener('click', this.clickHandler)
	},
	beforeDestroy() {
		document.removeEventListener('click', this.clickHandler)
	},
	updated() {
		this.$emit('selected-items', this.selected_items)
	}

}
</script>

<style scoped>
	.input-wrapper {
		position: relative;
	}

	.datalist-checkbox-wrapper input:focus-visible + label {
		outline: 2px solid var(--dark-blue);
		outline-offset: -3px;
	}

	.datalist-checkbox-wrapper input:checked + label {
		background-color: var(--light-blue);
		color: #ffffff;
	}

	.datalist-checkbox-wrapper {
		display: block;
		width: 100%;
	}

	.datalist-checkbox-wrapper label {
		display: block;
		width: 100%;
		padding: 5px 10px;
	}

	#webinars-filter {
		padding: 0; margin: 0 5px 0 5px;
		display: flex;
		position: absolute;
		background-color: #ffffff;
		flex-direction: column;
		justify-content: flex-start;
		align-content: stretch;
		border: none;
		border-radius: 0 0 9px 9px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		max-height: 250px;
		overflow-y: scroll;
		width: 100%;
	}

</style>
