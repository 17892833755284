<template>
	<main class="vcc-main webinars-archive-page">
		<h1 class="heading heading__hr hr__bold">Архив вебинаров</h1>
		<section class="search-section">
			<form @submit.prevent="">
				<label for="webinars-search" hidden>Поиск по архиву вебинаров</label>
				<input type="text" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req">
				<vcc-datalist title="Фильтр по видам деятельности" :items="getBusinessTypes" placeholder="Выберите вид деятельности" @selected-items="filter.filter_by = $event"/>
			</form>
			<section class="sorting-section">
				<hr class="hr__blue">
				<div class="sorting--info">
					<dl class="dl__theme sorting-section--dl">
						<dt>Соответствует запросу</dt>
						<dd><b>{{filteredItems.length}}</b></dd>
					</dl>
					<dl class="dl__theme sorting-section--dl">
						<dt>Всего в базе</dt>
						<dd><b>{{getWebinars.length}}</b></dd>
					</dl>
					<dl class="dl__theme dl--filter sorting-section--dl">
						<dt><b>Сортировать</b></dt>
						<dd>
							<div class="filter-group">
								<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
								<label for="asc" class="sorting-by">с начала</label>
							</div>
							<div class="filter-group">
								<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
								<label for="desc" class="sorting-by">с конца</label>
							</div>
							<div class="filter-group">
								<vc-date-picker v-model="filter.date_range" is-range>
									<template v-slot="{ inputValue, inputEvents }">
										<input name="sort-date" id="filter-date" class="visually-hidden date-picker" v-model="inputValue.start" v-on="inputEvents.start">
										<input class="visually-hidden date-picker" v-model="inputValue.end" v-on="inputEvents.end">
									</template>
								</vc-date-picker>
								<label for="filter-date" :class="['sorting-by', { 'black': filter.date_range.start != null }]">
									по дате
									<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
								</label>
							</div>
						</dd>
					</dl>
				</div>
			</section>
		</section>
		<ul class="webinars-archive--webinars-list" v-if="filteredItems.length > 0">
			<li v-for="webinar, key in paginatedItems" :key="'webinar-item-'+key">
				<kb-webinar-card :card-info="webinar" card-type="webinar" :no-footer="true"/>
			</li>
		</ul>
		<div class="no-webinars" v-else>
			<h4>По вашему запросу не нашлось вебинаров</h4>
			<p>Попробуйте применить другие фильтры или другой запрос.</p>
		</div>
		<pagination-pages
			:current-page.sync="currentPage"
			:items-count="filteredItems.length"
			:pages-count="pageCount"
			:btn-type="isMobile ? 'arrows' : 'word'"
			@prevPage="prevPage"
			@nextPage="nextPage"
		/>
		<datalist id="webinars-titles">
			<option v-for="webinar, key in getWebinars" :key="'webinar-item-finded'+key" :value="webinar.title"></option>
		</datalist>
		<faq subheading="true"/>
	</main>
</template>

<script>
import deviceWidth from '@/mixins/deviceWidth.js'
import WebinarCard from '@/components/cards/KbCard.vue'
import Faq from '@/components/FaqSection.vue'
import Datalist from '@/components/forms/inputs/Datalist.vue'
import { mapGetters, mapActions } from 'vuex'
import PaginationPages from '../../components/common/PaginationPages.vue'
export default {
	data() {
		return {
			items_per_page: 9,
			page_number: this.page || 1,
			search_req: null,
			filter: {
				date : null,
				sort_by: true,
				filter_by: [],
				date_range: {
					start: null,
					end: null
				},
			},
		}
	},
	mixins: [deviceWidth],
	props:['page'],
	components: {
		'faq': Faq,
		'vcc-datalist': Datalist,
		'kb-webinar-card': WebinarCard,
		PaginationPages
	},
	computed: {
		...mapGetters(['getBusinessTypes', 'getWebinars']),
		paginatedItems() {
			const start = (this.currentPage - 1) * this.items_per_page,
					end = start + this.items_per_page;
			return this.filteredItems.slice(start, end);
		},
		pageCount() {
			let l = this.filteredItems.length,
				s = this.items_per_page;
			return Math.ceil(l / s);
		},
		currentPage() {
			if(this.page > 0) {
				return Number(this.page)
			} else {
				return Number(this.page_number)
			}
		},
		filteredItems() {
			const webinars = this.getWebinars || null
			const searched_webinars = this.search_req != null ? webinars.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : webinars
			return searched_webinars.sort((a, b) => {
				if(this.filter.sort_by) {
					return new Date(b.started) - new Date(a.started)
				} else {
					return new Date(a.started) - new Date(b.started)
				}
			})
			.filter((item) => {
				if(this.filter.filter_by.length > 0) {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.dateIsInRange(item.started, this.filter.date_range) ? this.filter.filter_by.includes(item.business_type) : false
					} else {
						return this.filter.filter_by.includes(item.business_type)
					}
				} else {
					if(this.filter.date_range.start !== null) {
						return this.dateIsInRange(item.started, this.filter.date_range) ? item : false
					} else {
						return item
					}
				}

			})
		}
	},
	methods: {
		...mapActions(['fetchAllWebinars']),
		prevPage() {
			this.$router.push({
				name: 'WebinarsArchivePage',
				params: {
					page: this.currentPage-1
				}
			})
		},
		nextPage() {
			this.$router.push({
				name: 'WebinarsArchivePage',
				params: {
					page: this.currentPage+1
				}
			})
		},
		atStartPage() {
			this.$router.push({
				name: 'WebinarsArchivePage',
				params: {
					page: 1
				}
			})
		},
		atEndPage() {
			this.$router.push({
				name: 'WebinarsArchivePage',
				params: {
					page: this.pageCount()
				}
			})
		},

	},
	created() {
		if(this.getWebinars.length == 0) {
			this.fetchAllWebinars()
		}
	}
}
</script>


<style>

</style>
